<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-5 text-center">
          <span class="skeleton-box w-100 mt-1" style="height: 200px"></span>
        </div>

        <div class="col-md-7">
          <div class="mt-3 mt-lg-0">
            <div class="mb-2">
              <span class="skeleton-box w-100 mx-1"></span>
            </div>

            <div class="mb-2">
              <span class="skeleton-box w-100 mt-1" style="height: 80px"></span>
            </div>

            <div class="mb-2 d-flex justify-content-between">
              <span class="skeleton-box col-3 mt-1" style="height: 35px"></span>
              <span class="skeleton-box col-3 mt-1" style="height: 35px"></span>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="mb-2">
            <span class="skeleton-box col-2 mt-1"></span>
          </div>

          <div class="mt-3">
            <span class="skeleton-box w-100 mt-1"></span>
            <span class="skeleton-box w-100 mt-1"></span>
            <span class="skeleton-box w-100 mt-1"></span>
            <span class="skeleton-box w-100 mt-1"></span>
            <span class="skeleton-box w-100 mt-1"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
