<template>
  <Layout :pagetitle="'Produto'">
    <div class="row">
      <div class="col-lg-12">
        <div v-if="isLoading">
          <skeletonProduct />
        </div>
        <div v-else class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-5 text-center">
                <img
                  :src="product.imageUrl"
                  class="rounded product-image"
                  alt="image-product"
                  style="max-height: 200px"
                  @mouseover="zoomIn"
                  @mouseout="zoomOut"
                />
              </div>
              <div class="col-md-7">
                <div class="mt-3 mt-lg-0">
                  <div class="text-container" style="max-height: 5.4em">
                    <h4 class="mb-2 font-size-20 text-ellipsis" style="-webkit-line-clamp: 3">
                      {{ product.title }}
                    </h4>
                  </div>

                  <div class="d-flex justify-content-end">
                    <span :class="`badge-lg p-1 px-2 rounded-pill badge-soft-${company.color ? company.color : 'primary'}`">
                      {{ category.name }}
                    </span>
                  </div>

                  <div :class="`mt-3 d-flex ${product.highlight ? 'justify-content-between' : 'justify-content-end'}`">
                    <div class="d-flex bg-light rounded px-2" v-if="product.highlight">
                      <p class="text-muted mb-0">
                        <i class="bx bxs-star text-warning"></i>
                        Destaque
                      </p>
                    </div>

                    <p class="text-muted mb-0">
                      {{ product.updatedAt ? $utils.formatDateTime(product.updatedAt) : $utils.formatDateTime(product.createdAt) }}
                    </p>
                  </div>

                  <h6 class="mt-4">Sobre este item</h6>
                  <div class="text-container" style="max-height: 18em">
                    <h5 class="text-muted text-ellipsis" style="-webkit-line-clamp: 10">
                      {{ product.desc }}
                    </h5>
                  </div>

                  <h3 class="mb-0 mt-4">
                    <span class="text-muted me-2" v-if="product.promoPrice">
                      <del class="font-size-22 fw-normal">
                        {{ $utils.formatMoney(product.price) }}
                      </del>
                    </span>
                    <b>{{ product.promoPrice ? $utils.formatMoney(product.promoPrice) : $utils.formatMoney(product.price) }}</b>
                  </h3>

                  <div :class="`mt-3 d-flex justify-content-${product.link ? 'between': 'end'}`">
                    <img
                      v-if="product.link"
                      :src="platform.imageUrl"
                      class="mt-2"
                      alt="image-platform"
                      height="25"
                      width="105"
                    />
                    <button
                      type="button"
                      :class="`btn btn-lg btn-rounded waves-effect waves-light btn-${company.color ? company.color : 'primary'}`"
                      @click="product.link ? openLink(product.urlLink) : addToCart(product)"
                    >
                      <i class="bx bx-cart me-2 font-size-15 align-middle"></i>
                      {{ product.link ? "Ir para Oferta" : "Comprar" }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <h5>Informações sobre o produto</h5>
                <div>
                  <ul class="list-unstyled ps-0 mb-0 mt-3" v-if="product.features && product.features.length > 0">
                    <li v-for="(feature, index) in product.features" :key="index">
                      <div class="text-container" style="max-height: 5.4em">
                        <p class="text-muted mb-1 text-ellipsis" style="-webkit-line-clamp: 3">
                          <i :class="`mdi mdi-circle-medium align-middle me-1 text-${company.color ? company.color : 'primary'}`"></i>
                          {{ feature }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";
import skeletonProduct from "../../../components/effects/skeletonProduct.vue";

export default {
  data() {
    return {
      slug: this.$route.params.slug,
      idProduct: this.$route.params.idProduct,
      company: {},
      product: {},
      category: {},
      platform: {},
    };
  },
  components: {
    Layout,
    skeletonProduct,
  },
  async created() {
    this.company = await this.$utils.getCompanyBySlug(this.slug);

    this.setLoading(true);
    try {
      const res = await this.$api.get(`product/${this.idProduct}`);
      if (res.data.active == false) {
        this.$customAlert({
          type: "warning",
          msg: "Produto não encontrado",
        });
        this.$router.push({ name: "companyPrincipal" });
      }
      this.product = res.data;
    } catch (err) {
      this.setLoading(false);
      this.$customAlert({
        type: "error",
        msg: err?.data?.message || "Erro ao buscar produto",
      });
    }

    try {
      const res = await this.$api.get(`category/${this.product.idCategory}`);
      this.category = res.data;
    } catch (err) {
      this.setLoading(false);
      this.$customAlert({
        type: "error",
        msg: err?.data?.message || "Erro ao buscar categoria",
      });
    }

    if (this.product.link) {
      try {
        const res = await this.$api.get(`platform/${this.product.idPlatform}`);
        this.platform = res.data;
      } catch (err) {
        this.setLoading(false);
        this.$customAlert({
          type: "error",
          msg: err?.data?.message || "Erro ao buscar plataforma",
        });
      }
    }
    
    this.setLoading(false);

    if (!this.company || !this.product || !this.category) {
      console.log("IR PARA PÁGINA NÃO ENCONTRADA!");
    }
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState(["isLoading"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    addToCart(product) {
      this.$store.dispatch("addToCart", product);
      console.log(this.$store.state.cart);
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    zoomIn(event) {
      event.target.style.transform = "scale(1.1)";
    },
    zoomOut(event) {
      event.target.style.transform = "scale(1)";
    },
  },
};
</script>

<style scoped>
.text-container {
  /*max-height: 3.6em; /* Duas linhas de altura (cada linha aproximadamente 1.8em) */
  overflow: hidden;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*-webkit-line-clamp: 2; /* Máximo de 2 linhas */
  overflow: hidden;
  text-overflow: ellipsis; /* Adiciona reticências no final do texto */
}

.product-image {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Adiciona uma transição suave para o efeito de zoom */
}
.product-image:hover {
  transform: scale(
    1.1
  ); /* Aplica um aumento de escala ao passar o mouse sobre a imagem */
}
</style>
